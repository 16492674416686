import type { SubSelectDefinition, RecordSourceOptions, ItemModel, DataItemModel, DataObjectOptions } from './types.ts';
import type { Field } from './DataObject.Fields.ts';

import { BulkOperation, logger } from 'o365-utils';
import DataObject from './DataObject.ts';
import DataItem from './DataObject.Item.ts';
import DataHandler from './DataObject.DataHandler.ts';

declare module 'o365-dataobject' {
    interface DataObject<T> {
        subSelect: DataObjectSubSelect<T>;
        hasSubSelect: boolean;
    }
}
declare module './DataObject.ts' {
    interface DataObject<T> {
        subSelect: DataObjectSubSelect<T>;
        hasSubSelect: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'subSelect': {
        get() {
            if (this._subSelect == null) {
                this._subSelect = new DataObjectSubSelect(this);
            }
            return this._subSelect;
        }
    },
    'hasSubSelect': {
        get() {
            return !!this._subSelect;
        }
    },
});  

const subSelectsMap = new Map<string, (pItem: any) => void>();
const super_initialize = DataItem.prototype.initialize;
Object.defineProperty(DataItem.prototype, 'initialize', {
    get() {
        return function (this: DataItem) {
            super_initialize.call(this);
            const key = getItemKey(this);
            if (subSelectsMap.has(key)) {
                subSelectsMap.get(key)!(this);
            }
        }
    }
});

/**
 * DataObject extension for sub selecting values from other views through data api.
 * Only supports readonly values, sub selected fields cannot be saved. Can connect one-to-one or one-to-many values.
 */
export default class DataObjectSubSelect<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _initialized = false;
    private _dataHandler: DataHandler;

    private _definitions: SubSelectDefinition[] = [];

    private _bulkOperations = new Map<string, {
        operation: BulkOperation<DataItemModel<T>, SubSelectResult>,
        fields: SubSelectField[]
    }>();

    get key() {
        return `${this._dataObject.appId}.${this._dataObject.id}`;
    }

    get definitions() { return this._definitions; }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;

        this._dataHandler = new DataHandler();
    }

    initialize(pOptions: DataObjectOptions ) {
        if (this._initialized) { return; }
        this._initialized = true;
        if (pOptions.subSelectDefinitions) {
            pOptions.subSelectDefinitions.forEach(definition => {
                if (this._isValidSubSelectDefinition(definition)) {
                    this._definitions.push(definition);
                }
            })
        }
        subSelectsMap.set(this.key, this.extendItem.bind(this));
        this._updateDataObjectFields();

        this._dataObject.storage.data.forEach(item => {
            this.extendItem(item);
        });
    }

    /** Extend data item with sub select aliases */
    extendItem(pItem: DataItem<T>) {
        this._definitions.forEach(definition => {
            if (!pItem.hasOwnProperty(definition.alias)) {
                (pItem as any)[definition.alias] = { item: {}, _initialized: false };
            }
            const scope: any = (pItem as any)[definition.alias];
            if (scope._initializedFiields == null) {
                scope._initializedFiields = {};
            }
            const loadFn = (field: SubSelectField) => {
                scope._initializedFiields[field.name] = true;
                this.loadScopeForItem(pItem as DataItemModel<T>, definition, field);
            };

            definition.fields.forEach(field => {
                Object.defineProperty(scope, field.name, {
                    get() {
                        if (!scope._initializedFiields[field.name]) {
                            loadFn(field);
                        }
                        return scope.item[field.name];
                    }
                });
                Object.defineProperty(pItem, `${definition.alias}.${field.name}`, {
                    get() {
                        return (pItem as any)[definition.alias][field.name];
                    }
                });
            });
        });
    }

    /**
     * Retrieve sub select value, returns a promise.
     * @param pDataItem Item from which to sub select
     * @param pField Field to sub select, must include the alias together with the field name.
     * @example `getAsync(item, 'A2.Value')`
     */
    getAsync(pDataItem: DataItemModel<T>, pField: string) {
        const alias = pField.split('.')[0];
        const fieldName = pField.split('.')[1];
        if (pDataItem[alias]?.[fieldName]) {
            return Promise.resolve(pDataItem[alias][fieldName]);
        } else if (pDataItem[alias]?._promise) {
            return pDataItem[alias]._promise.then(() => pDataItem[alias][fieldName]);
        } else {
            return Promise.reject(new SubSelectError(`Could not find any loading promise for alias: ${alias}`));
        }
    }

    /**
     * Load sub select definition values for a data item
     */
    async loadScopeForItem(pItem: DataItemModel<T>, pDefinition: SubSelectDefinition, pField: SubSelectField) {
        const subSelect = this._getBulkOperationForDefinition(pDefinition);
        
        const scope = pItem[pDefinition.alias];
        let res = () => {};
        const promise = new Promise<void>(r => res = r);
        scope._promise = promise;
        // subSelect.fields.push(pField);
        try {
            subSelect.fields.push(pField);
            if (pItem.isNewRecord) { return; }
            const result = await subSelect.operation.addToQueue(pItem) ?? [];
            if (scope.item == null) { scope.item = {}; }
            if (pField.subFields) {
                scope.item[pField.name] = result.map(value => {
                    const item: Record<string, any> = {};
                    pField.subFields!.forEach(field => {
                        item[field] = value[field]; 
                    });
                    return item;
                });
            } else {
                scope.item[pField.name] = result[0]?.[pField.name];
            }
        } catch (ex) {
            logger.error(ex);
        } finally {
            res();
        }
    }

    /** Get recordsource request options for sub selects */
    getSubSelectOptions() {
        const options: RecordSourceOptions['subSelectAliases'] = {};
        this._definitions.forEach(definition => {
            const fields: Record<string, string> = {};
            definition.fields.forEach(field => {
                fields[field.name] = field.filterParams?.column ?? field.name
            });
            options[definition.alias] = {
                viewName: definition.viewName,
                binding: this._getBindingString(definition.bindings),
                fields: fields
            };
        });
        return options;
    }

    private _getBulkOperationForDefinition(pDefinition: SubSelectDefinition) {
        const definitionKey = this._getBulkOperationKey(pDefinition);
        if (!this._bulkOperations.has(definitionKey)) {
            const canUseInList = pDefinition.bindings.length == 1 && pDefinition.bindings[0].operator == 'equals';
            if (canUseInList) {
                const bindingDefinition = pDefinition.bindings[0];
                this._bulkOperations.set(definitionKey, {
                    operation: new BulkOperation<DataItemModel<T>, SubSelectResult>({
                        bulkSize: 500, // TODO: Consolidate per field bulk operations into per row operations when possible, increasing bulk size as a temp solution
                        bulkOperation: async (pQueue) => {
                            const whereClause = `${bindingDefinition.detailField} IN (${pQueue.map(item => `'${item.value[bindingDefinition.masterField]}'`).join(',')})`;

                            const options = this._getOptions(pDefinition);
                            options.whereClause = whereClause;
                            this._dataHandler.updateViewName(`${this._dataObject.id}_${pDefinition.alias}_${pDefinition.viewName}`);
                            const data = await this._dataHandler.retrieve(options);

                            pQueue.forEach(item => {
                                const values = data.filter(x => {
                                    return x[bindingDefinition.detailField] == item.value[bindingDefinition.masterField]
                                });
                                item.res(values);

                                // if (pDefinition.fieldName) {
                                //     item.res(values);
                                // } else {
                                //     if (values.length > 1) {
                                //         item.rej(new SubSelectError('Sub select without field name returned more than 1 row'));
                                //     } else {
                                //         item.res(values[0] ?? {})
                                //     }
                                // }
                            });
                        }
                    }),
                    fields: []
                });
            } else {
                this._bulkOperations.set(definitionKey, {
                    operation: new BulkOperation<DataItemModel<T>, SubSelectResult>({
                        bulkSize: 500, // TODO: Consolidate per field bulk operations into per row operations when possible, increasing bulk size as a temp solution
                        bulkOperation: async (pQueue) => {
                            const promises = pQueue.map((item) => {
                                const whereClause = pDefinition.bindings.map((bindingDefinition) => `${bindingDefinition.detailField} = '${item.value[bindingDefinition.masterField]}'`).join(' AND ');
                                const options = this._getOptions(pDefinition);
                                options.whereClause = whereClause;
                                this._dataHandler.updateViewName(`${this._dataObject.id}_${pDefinition.alias}_${pDefinition.viewName}`);
                                return this._dataHandler.retrieve(options).then(data => {
                                    const values = data.filter(x => {
                                        return pDefinition.bindings.every((bindingDefinition) => {
                                            return x[bindingDefinition.detailField] == item.value[bindingDefinition.masterField]
                                        })
                                    });
                                    item.res(values);
                                });
                            });
                            await Promise.all(promises);
                        }
                    }),
                    fields: []
                });
            }
        }
        return this._bulkOperations.get(definitionKey)!;

    }

    private _getBulkOperationKey(pDefinition: SubSelectDefinition) {
        return JSON.stringify(pDefinition);
        // return `v:${pDefinition.viewName};a:${pDefinition.alias};fn:${pDefinition.fieldName}`;
    }

    /** Get options for executing bulk sub select retreive */
    private _getOptions(pDefinition: SubSelectDefinition): RecordSourceOptions {
        const definitionKey = this._getBulkOperationKey(pDefinition);
        const bulkOperation = this._bulkOperations.get(definitionKey);
        const fields = new Set<string>();
        if (bulkOperation) {
            const fieldsToSelect = bulkOperation.fields.splice(0, bulkOperation.fields.length);
            fieldsToSelect.forEach(field => {
                if (field.subFields) {
                    field.subFields.forEach(subField => fields.add(subField));
                } else {
                    fields.add(field.name);
                }
            });
        }
        pDefinition.bindings.forEach(binding => {
            fields.add(binding.detailField);
        });
        const options = {
            viewName: pDefinition.viewName,
            fields: Array.from(fields.values()).map(field => ({ name: field })),
            maxRecords: -1,
            skip: 0,
        } as RecordSourceOptions;
        if (pDefinition.distinctRows) {
            options.distinctRows = true;
        }
        return options;
    }

    private _isValidSubSelectDefinition(pDefinition: SubSelectDefinition) {
        if (!pDefinition.alias) {
            logger.warn('Sub select definition is missing an alias', pDefinition);
            return false;
        } else if (this._definitions.findIndex(def => def.alias == pDefinition.alias) !== -1) {
            logger.warn(`Sub select definition with alias ${pDefinition.alias} already exists`, pDefinition);
            return false;
        } else if (!pDefinition.viewName) {
            logger.warn('Sub select definition is missing a view name', pDefinition);
            return false;
        } else if (pDefinition.fields == null || !pDefinition.fields.length) {
            logger.warn('Sub select definition is missing fields', pDefinition);
            return false;
        } else if (pDefinition.bindings == null || !pDefinition.bindings.length) {
            logger.warn('Sub select definition is missing bindings', pDefinition);
            return false;
        } else if (pDefinition.bindings.some(binding => {
            return !binding.detailField || !binding.masterField || !binding.operator
        })) {
            logger.warn('Sub select definition has bad bindings', pDefinition);
            return false;
        } else {
            return true;
        }
    }

    /**
     * Create fields on the dataobject for sub select definitions
     */
    private _updateDataObjectFields() {
        const newFields: Field[] = [];
        this._definitions.forEach(definition => {
            definition.fields.forEach(field => {
                const dataField = this._dataObject.fields.createField({
                    name: `${definition.alias}.${field.name}`,
                    type: field.type
                });
                if (field.filterParams) {
                    dataField.filter = 'OFilter';
                    if (field.filterParams.disableDistinct) {
                        dataField.disableDistinct = field.filterParams.disableDistinct;
                    }
                } else {
                    // if (field.subFields == null && field.type) {
                    //     field.filterParams = {
                    //         column: field.name
                    //     };
                    //     dataField.distinct = {
                    //         column: field.name,
                    //         columns: [field.name]
                    //     };
                    // }
                    dataField.disableDistinct = true;
                }
                newFields.push(dataField);
            });
        });
        this._dataObject.filterObject.updateColumns(newFields);
        // return;
        this._definitions.forEach(definition => {
            if (definition.bindings.length != 1 || definition.bindings[0].operator != 'equals') { return; }
            const binding = definition.bindings[0];
            definition.fields.forEach(field => {
                const fieldName = `${definition.alias}.${field.name}`;
                // if (field.filterParams == null) { return; }
                // if (field.subFields == null) {
                //     this._dataObject.filterObject.getItem(fieldName).operator = 'exists_clause';
                // } else {
                this._dataObject.filterObject.setColumnExistsOptions(fieldName, {
                    viewName: definition.viewName,
                    bindT1: binding.masterField,
                    bindT2: binding.detailField,
                    operator: '=',
                    column: field.filterParams?.column ?? field.name,
                    displayColumn: field.filterParams?.displayColumn ?? field.filterParams?.column ?? field.name,
                    columnType:field.filterParams?.columnType
                });
                this._dataObject.filterObject.setColumnDistinctOptions(fieldName, {
                    definitionProc: field.filterParams?.definitionProc ?? null
                });
                this._dataObject.filterObject.getItem(fieldName).useExist = true;
                // }
            });
        });
    }

    /** Get exist clause binding string from sub select bindings array */
    private _getBindingString(pBinding: SubSelectDefinition['bindings']) {
        const clauses = pBinding.map(binding => {
            switch (binding.operator) {
                case 'equals':
                    return `T2.[${binding.detailField}] = T1.[${binding.masterField}]`;
                case 'contains':
                    return `T2.[${binding.detailField}] LIKE CONCAT('%',T1.[${binding.masterField}], '%')`;
                case 'beginswith':
                    return `T2.[${binding.detailField}] LIKE CONCAT(T1.[${binding.masterField}], '%')`;
                case 'endswith':
                    return `T2.[${binding.detailField}] LIKE CONCAT('%',T1.[${binding.masterField}])`;
                default:
                    throw new Error('Invalid sub select operator provided');
            }
        });
        return clauses.join(' AND ');
    }
}

function getItemKey(pDataItem: DataItem) {
    return `${pDataItem.appId}.${pDataItem.dataObjectId}`;
}

export type SubSelectResult = Record<string, any>[];

export type SubSelectField = SubSelectDefinition['fields'][0];

class SubSelectError extends Error {
    constructor(pMessage: string) {
        super(pMessage);
    }
}